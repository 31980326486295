<template>
	<ion-page>
		<ion-content :fullscreen="true">
			<form id="container" @submit.prevent="login">
				<ion-grid>
					<ion-row>
						<ion-col>
							<ion-item lines="none">
								<ion-img id="logo" src="/assets/img/icons/apple-touch-icon-152x152.png"></ion-img>
							</ion-item>
						</ion-col>
					</ion-row>

					<ion-row>
						<ion-col class="ion-text-center">
							<h4>Solicitação de Fretes</h4>
						</ion-col>
					</ion-row>

                    <ion-row class="ion-justify-content-center">
                        <ion-col size-xl="4" size-lg="5" size-md="6">
							<ion-list>
								<ion-item>
									<ion-input
										placeholder="E-mail"
										type="email"
										v-model="payload.email"
										@keyup.enter="login"
										v-lowercase
										:autofocus="true"
										ref="root"
									></ion-input>
								</ion-item>

								<ion-item>
									<ion-input
										placeholder="Senha"
										type="password"
										v-model="payload.password"
										@keyup.enter="login"
									></ion-input>
								</ion-item>
							</ion-list>
						</ion-col>
					</ion-row>

                    <ion-row class="ion-justify-content-center">
                        <ion-col size-xl="4" size-lg="5" size-md="6" class="ion-text-center">
							<ion-button v-if="logando" color="primary" expand="full" disabled>
								<ion-spinner name="crescent"></ion-spinner>
							</ion-button>
							<ion-button
								expand="full"
								type="submit"
								v-else
							>
								Entrar
							</ion-button>
						</ion-col>
					</ion-row>
				</ion-grid>
			</form>
		</ion-content>
	</ion-page>
</template>

<script>
	//Ícones Ionic
	import {
		logoGoogle
	} from "ionicons/icons";
	import { addIcons } from "ionicons";
	addIcons({
		"logo-google": logoGoogle,
	});

	//Componentes Ionic
	import {
		IonContent,
		IonPage,
		IonGrid,
		IonRow,
		IonCol,
		IonList,
		IonButton,
		IonInput,
		IonImg,
		IonItem,
		IonSpinner
	} from "@ionic/vue";

	//Mixins
	import TitleMixin from "@/mixins/TitleMixin";

	//Aux
	import {toast} from "@/helpers";

	export default {
		name: "LoginIndex",

		data() {
			return {
				fix: 0,
				logando: false,
				payload: {
					email: '',
					password: '',
					recaptcha_token: '6LeNCdkaAAAAABSpAdCA9z0_zdXwnFFnF2HddFY1'
				}
			}
		},

		methods: {
			async login() {
				this.logando = true;

				//Aguarda até que o recaptcha tenha sido carregado totalmente
				await this.$recaptchaLoaded();

				this.payload.recaptcha_token = await this.$recaptcha('login');

				await this.$store.dispatch('login', this.payload);

				this.logando = false;
			},

			solicitarRedefinicao(event) {
				event.preventDefault();
				toast('Em desenvolvimento!');
			}
		},

		mixins: [
			TitleMixin
		],

		mounted() {
			const vm = this;

			//https://michaelnthiessen.com/force-re-render
			//Truque para fazer com que o DOM atualize para pegar o e-mail e senha se o navegador "largou", com o autofill
			setTimeout(() => {
				vm.fix++;
			}, 400);
		},

		components: {
			IonPage,
			IonContent,
			IonGrid,
			IonRow,
			IonCol,
			IonList,
			IonButton,
			IonInput,
			IonImg,
			IonItem,
			IonSpinner
		}
	}
</script>

<style scoped>
	a {
		text-decoration: none;
	}

	@media (prefers-color-scheme: dark) {
		a {
			color: white;
		}
	}

	#container {
		display: flex;
		align-items: center;
		justify-content: center;
		height: 100%;
	}

	#logo {
		margin: 0 auto;
	}
</style>
